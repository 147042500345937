import React, { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
import { Context } from '../../../../types/frontastic'
import tagManager from '../../../../domain/TagManager'
import GtmService from '../../../../services/gtm'
import OldTagManager from '../../../../domain/OldTagManager'
import OldGtmService from '../../../../services/oldGtm'
import Slider from '../../../atoms/slider'
import ProductTile from '../Hits/ProductTile'
import ProductColorService from '../../../../services/color'

type Props = {
    hits: any
    context: Context
    objectId: string | null
    gtmCategoryName: string
    gtmCategoryId: string
    renderAsSlider: boolean
    swiperColor: string
    alignment: string
    node: object
    nameCategory: any
} & WrappedComponentProps

type State = {
    colors: any
}

class ProductHits extends Component<Props, State> {
    state = {
        colors: {},
    }

    protected sentinel: HTMLElement | null = null

    protected observer: IntersectionObserver | null = null

    public componentDidMount() {
        const categoryName = this.props.gtmCategoryName
        const categoryId = this.props.gtmCategoryId
        const gtmService = new GtmService(this.props.hits)
        const productListGTM = gtmService.createProductListGTM(categoryName, categoryId)
        tagManager.productImpressions(productListGTM, categoryName, categoryId)

        // for universal analytics ( will be removed )
        const oldGtmService = new OldGtmService(this.props.hits)
        const oldProductListGTM = oldGtmService.createProductListGTM(`Page: ${categoryName}`)
        OldTagManager.productImpressions(oldProductListGTM)
        OldTagManager.hitViewdImpressions()


        const { hits } = this.props
        const { colors } = this.state

        const modelKeys = []

        hits.forEach((hit) => {
            const productColors = colors[hit.root_model_key] ?? null
            if (productColors == null) {
                colors[hit.root_model_key] = [hit]
                modelKeys.push(hit.root_model_key)
            }
        })
        if (modelKeys.length > 0) {
            this.setState({ colors })
            ProductColorService.getColors(modelKeys)
                .then((responseColors) => {
                    responseColors.forEach((hitColor: any) => {
                        const hitColors = colors[hitColor.root_model_key] ?? []
                        const objectIds = hitColors.map((hit) => hit.product_id)
                        if (!objectIds.includes(hitColor.product_id)) {
                            hitColors.push(hitColor)
                        }
                        colors[hitColor.root_model_key] = hitColors
                    })

                    this.setState({ colors })
                })
        }
    }

    public componentWillUnmount() {
        this.observer?.disconnect()
    }

    bindColors = (hits, colors) => {
        if (!colors || hits.length == 0) {
            return hits
        }

        hits.forEach((hit) => {
            if (hit.root_model_key) {
                hit.colors = []
                const productColors = colors[hit.root_model_key] ?? []
                productColors.forEach((productColor) => {
                    productColor.isActive = productColor.sku === hit.sku
                    hit.colors.push(productColor)
                })
            }
        })
        return hits
    }

    renderProductTilesAsList = () => {
        const {
            nameCategory, node, context,
        } = this.props
        // @ts-ignore
        const { algoliaIndexName } = context?.projectConfiguration || {}
        const hits = this.bindColors(this.props.hits, this.state.colors)

        return (
            hits.map((hit, index) => (
                <ProductTile
                    key={hit.product_id + index}
                    position={index + 1}
                    product={hit}
                    nameCategory={nameCategory}
                    colors={hit.colors}
                    // @ts-ignore
                    node={node}
                    algoliaIndexName={algoliaIndexName}
                />
            ))
        )
    }

    renderProductTilesAsSlider = () => {
        const {
            nameCategory, node, context,
        } = this.props

        // @ts-ignore
        const { algoliaIndexName } = context?.projectConfiguration || {}
        const hits = this.bindColors(this.props.hits, this.state.colors)

        return (
            <Slider
                spaceBetween={0}
                slidesPerView={2}
                slidesPerGroup={2}
                loop={false}
                swiperColor={this.props.swiperColor}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 12,
                    },
                    1024: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                        spaceBetween: 20,
                    },
                }}
            >
                {hits.map((hit, index) => (
                    <SwiperSlide key={hit.product_id}>
                        <ProductTile
                            key={hit.product_id + index}
                            position={index + 1}
                            product={hit}
                            nameCategory={nameCategory}
                            colors={hit.colors}
                            // @ts-ignore
                            node={node}
                            algoliaIndexName={algoliaIndexName}
                        />
                    </SwiperSlide>
                ))}
            </Slider>
        )
    }

    render() {
        if (!this.props.hits) {
            return null
        }

        const listView = this.renderProductTilesAsList()
        const sliderView = this.renderProductTilesAsSlider()

        return (
            <div className={'product-list--wrapper product-list-algolia product-tiles-algolia-wrapper'}>
                <div className={'product-list--component'}>
                    {this.props.renderAsSlider === false ? (
                        <div className={classnames('product-teaser-list', {
                            'justify-start': this.props.alignment === 'left',
                            'justify-center': this.props.alignment === 'center',
                            'justify-end': this.props.alignment === 'right',
                        })}
                        >
                            {listView}
                        </div>
                    ) : (
                        <>
                            {sliderView}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default injectIntl(ProductHits)
