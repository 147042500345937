import React from 'react'
import BaseDotted from './BaseDotted'
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from './index'

export type Props = {
    renderAtTop: boolean
    renderBlackBackground: boolean
}

const BorderOnTopAndBottom: React.FC<Props> = ({ renderAtTop, renderBlackBackground }: Props) => (
    <>
        <BaseDotted
            dottedSVG={renderAtTop
                ? (
                    <DottedFadebarVariantA5mm
                        fill={renderBlackBackground ? 'var(--color-white-100)' : 'var(--color-black-100)'}
                    />
                )
                : (
                    <DottedFadebarVariantB5mm
                        fill={renderBlackBackground ? 'var(--color-white-100)' : 'var(--color-black-100)'}
                    />
                )}
            height={14}
            isTop={renderAtTop}
            isBottom={!renderAtTop}
            isInsetTop={renderAtTop}
            isInsetBottom={!renderAtTop}
            isRotate={!renderAtTop}
        />
        <BaseDotted
            dottedSVG={renderAtTop
                ? (
                    <DottedFadebarVariantB5mm
                        fill={renderBlackBackground ? 'var(--color-white-100)' : 'var(--color-black-100)'}
                    />
                )
                : (
                    <DottedFadebarVariantA5mm
                        fill={renderBlackBackground ? 'var(--color-white-100)' : 'var(--color-black-100)'}
                    />
                )}
            height={14}
            isTop={renderAtTop}
            isBottom={!renderAtTop}
            isInsetTop={renderAtTop}
            isInsetBottom={!renderAtTop}
            isRotate={!renderAtTop}
            from={'right'}
        />
    </>
)

export default BorderOnTopAndBottom
